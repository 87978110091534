import {
    computed,
    defineComponent,
    onMounted,
    PropType, reactive,
} from 'vue';

import {
    getProductDetailUrl,
    getLimitStringByWord,
    getProductDisplayTitle,
    getUnitString,
    getWeightString,
    setSeo
} from "@/utils/displayUtil";
import {getPhotoUrl} from "@/Directive";
import {useContext} from "@/Ph";
import Decimal from "decimal.js-light";
import {Const} from "@/service/model/Const";
import RecommendProduct from "@/service/model/index/RecommendProduct";
import {coIndexController} from "../../../service/CoIndexController";
export default defineComponent({
    name:'ProductsItem',
    props:{
        products:{
            type: Array as PropType<RecommendProduct[]>,
            required: true
        }
    },
    setup(props) {

        const productsWithIndex = reactive(
            props.products.map((product) => ({
                ...product,
                index: 0, // 初始化 index
            })));

        const context = useContext()

        onMounted(() => {
        });

        const changeImage = (direction,recommendProduct:RecommendProduct) => {
            const product = productsWithIndex.find((p) => p.product.id === recommendProduct.product.id);
            if (product && product.images.length > 1) {
                product.index = (product.index + direction + product.images.length) % product.images.length;
            }
        };

        // 小图滑动方法
        const scrollImages = (direction, index)=> {
            // direction: 'prev' 或 'next'
            // 找到对应的 swiper-container 元素
            const swiperContainer = document.querySelector(`.small-img-container-${index}`);
            if (!swiperContainer) return;

            // 找到包含所有小图的容器
            const setListWrap = swiperContainer.querySelector('.setListWrap');
            if (!setListWrap) return;

            // 计算滚动的距离，一次滑动一屏的宽度
            const scrollAmount = setListWrap.clientWidth;

            // 找到当前的滚动位置
            let currentPosition = setListWrap.scrollLeft;

            // 计算新的滚动位置
            let newPosition;
            if (direction === 'prev') {
                newPosition = currentPosition - scrollAmount;
            } else {
                newPosition = currentPosition + scrollAmount;
            }

            // 确保新位置在合理范围内
            newPosition = Math.max(newPosition, 0);
            newPosition = Math.min(newPosition, setListWrap.scrollWidth - setListWrap.clientWidth);

            // 添加滑动样式类
            setListWrap.classList.add('scroll-left');

            // 滚动到新的位置
            setListWrap.scrollLeft = newPosition;

            // 等待滚动完成后移除滑动样式类
            setTimeout(() => {
                setListWrap.classList.remove('scroll-left');
            }, 600);
        }

        const getShippingWeight  = (productDetail:any) => {
            let productVolumeWeight = new Decimal(productDetail.batchVolume).div(Const.VOLUME_RATE).todp(0, Decimal.ROUND_HALF_UP).toNumber();
            if (productVolumeWeight>productDetail.batchWeight) {
                return productVolumeWeight;
            }
            return productDetail.batchWeight;
        }

        const getStepRange = (step2, step3) => {
            return step3 && step3 > 0
                ? `${step2}-${step3 - 1}`
                : `${step2}+`;
        }

        const changeCollective = async (baseId:number,productId:number)=>{
            let recommendProduct = await  coIndexController.changeCollective(productId);
            //替换元素
            for (let i = 0; i < productsWithIndex.length; i++) {
                if (productsWithIndex[i].product.id === baseId) {
                    productsWithIndex[i].product = recommendProduct.product;
                    productsWithIndex[i].images = recommendProduct.images;
                    productsWithIndex[i].index = 0;
                    break;
                }
            }
        }


        return {
            scrollImages,
            changeImage,
            getPhotoUrl,
            Const,
            getProductDetailUrl,
            getUnitString,
            getWeightString,
            getLimitStringByWord,
            getProductDisplayTitle,
            getShippingWeight,
            getStepRange,
            setSeo,
            context,
            changeCollective,
            productsWithIndex
        }
    }

})