<template>
  <div class="bannerWrap clearfix">
      <swiper
          class="bannerImg"
          :modules="modules"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          autoplay
          loop
          :navigation = "{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              uniqueNavElements: true
         }"
          :pagination="{clickable: true}"
      >
        <swiper-slide v-for="(item, index) in bannerAds">
          <a :href="item.info1" :title="item.alt">
            <img :src="item.img1" :alt="item.title" :key="index"/>
          </a>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev bannerPrev bannerPage hidden-xs"></div>
      <div class="swiper-button-next bannerNext  bannerPage hidden-xs"></div>
  </div>

  <!-- 小屏模块 -->
  <div class="classification hidden-sm hidden-md" :class="{ 'hasClearence': isShowBigSale }">
    <ul>
      <li class="allCategories" @click="handleOpenNavCate(true)">
        <a href="javascript:;" >
          <span></span>
          <i>All<br>Categories</i>
        </a>
      </li>
      <li class="smallLotIcon">
        <a href="/small-lot/1.html">
          <span></span>
          <i>Small Lot</i>
        </a>
      </li>
      <li class="diy">
        <a href="/theme/diy.html">
          <span></span>
          <i>DIY</i>
        </a>
      </li>
      <li class="dailyDeals" v-if="isShowBigSale">
        <a href="/clearance/1.html">
          <span></span>
          <i>Clearance</i>
        </a>
      </li>
    </ul>
  </div>
  <!-- 倒计时 -->
  <Promotion class="hidden-sm hidden-md"></Promotion>

  <!-- productBanner -->
  <div v-if="coIndexDto.settingRegCouponsBanner" class="productBanner">
    <a href="/Customer/SignIn?q=reg" :title="coIndexDto.settingRegCouponsBanner.title">
      <img :src="coIndexDto.settingRegCouponsBanner.wabBanner" :alt="coIndexDto.settingRegCouponsBanner.title">
    </a>
  </div>

  <!-- Coupons and Deals -->
  <div v-if="coIndexDto.couponsDeals && coIndexDto.couponsDeals.length >0" class="categoriesItem recommendedItem titleItem">
     <p class="titleText clearfix">
       <span></span><span class="middle"></span><span></span>
       <b>RECOMMENDED CATEGORIES</b>
       <span></span><span class="middle"></span><span></span>
     </p>
     <ul class="clearfix">
       <li v-for="(b, index) in coIndexDto.couponsDeals" :key="index" :class="{ 'laster': (index + 1) % 4 === 0 }">
         <a :href="b.info1" :title="b.title">
           <img :alt="b.title" v-lazy-img="b.img1">
           <strong>{{ b.title }}</strong>
         </a>
       </li>
     </ul>
   </div>

    <client-only>
      <div class="newArrivalsItem titleItem">
        <p class="titleText clearfix">
          <span></span>
          <span class="middle"></span>
          <span></span>
          <b>NEW ARRIVALS</b>
          <span></span>
          <span class="middle"></span>
          <span></span>
        </p>
        <ProductsItem v-if="loadIndexRecommendProducts.newArrivalsProducts" :products="loadIndexRecommendProducts.newArrivalsProducts" />
      </div>
      <div class="hotSaleItem titleItem">
        <p class="titleText clearfix">
          <span></span>
          <span class="middle"></span>
          <span></span>
          <b>HOT SALE ITEMS</b>
          <span></span>
          <span class="middle"></span>
          <span></span>
        </p>
        <ProductsItem v-if="loadIndexRecommendProducts.hotSaleProducts" :products="loadIndexRecommendProducts.hotSaleProducts" />
      </div>
      <!-- brand -->
      <div v-if="coIndexDto.brandBanner && coIndexDto.brandBanner.length > 0" class="brandShow">
        <a :href="b.info1" :title="b.title" v-for="(b, bs) in coIndexDto.brandBanner" :key="bs">
          <img :alt="b.title" v-lazy-img="b.img1">
        </a>
      </div>
      <!-- featured Items -->
      <div v-if="coIndexDto.specialCatBanner !=null && coIndexDto.specialCatBanner.length > 0" class="categoriesItem featuredItem titleItem">
        <p class="titleText clearfix">
          <span></span><span class="middle"></span><span></span><b>FEATURED CATEGORIES</b><span></span><span class="middle"></span><span></span>
        </p>
        <ul class="clearfix">
          <li v-for="(b, bs) in coIndexDto.specialCatBanner" :key="bs" :class="{ 'laster': (bs + 1) % 3 === 0 }">
            <a :href="b.info1" :title="b.title">
              <img :alt="b.title" v-lazy-img="b.img1">
              <strong>{{ b.title }}</strong>
            </a>
          </li>
        </ul>
      </div>

    </client-only>
  <!-- 手机弹窗 -->
  <client-only>
    <div class="ms-category-mask" v-if="isShowNavCate == true" :style="winStyle"></div>
    <section class="ms-category-drawer hidden-sm hidden-md" :class="{'open': isShowNavCate == true}" :style="winStyle">
      <div class="ms-category-head">
        <a class="ms-category-logo" href="/"><img src="/src/assets/images/header/logo.png" alt="cobeads"></a>
        <span class="ms-category-cancel" @click="handleOpenNavCate(false)"></span>
      </div>
      <div class="navList">
        <dl class="categoriesList">
          <dd><a class="ms-second-category" href="/wholesale-jewelry-beads/2.html">Jewelry Beads<em></em></a></dd>
          <dd><a class="ms-second-category" href="/wholesale-jewelry-findings/3.html">Jewelry Findings<em></em></a></dd>
          <dd><a class="ms-second-category" href="/wholesale-beading-supplies/4.html">Beading Supplies<em></em></a></dd>
          <dd><a class="ms-second-category" href="/wholesale-stringing-materials/5.html">Stringing Materials<em></em></a></dd>
          <dd><a class="ms-second-category" href="/wholesale-jewelry-watches/6.html">Jewelry &amp; Watches<em></em></a></dd>
          <dd><a class="ms-second-category" href="/wholesale-beauty-personal-care/551.html">Beauty &amp; Personal Care<em></em></a></dd>
          <dd><a class="ms-second-category" href="/wholesale-sewing-diy-crafts/736.html">Sewing &amp; DIY Crafts<em></em></a></dd>
          <dd><a class="ms-second-category" href="/wholesale-office-home-garden/901.html">Office &amp; Home &amp; Garden<em></em></a></dd>
        </dl>
      </div>
    </section>
  </client-only>

</template>

<script src="./Index.ts"></script>
<style src="./index.less" scoped lang="less"></style>