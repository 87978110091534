import {computed, defineComponent, inject, nextTick, onMounted, onServerPrefetch, reactive, ref, toRefs} from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Autoplay, Navigation} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import CoIndexDto from "@/service/model/index/CoIndexDto";
import {coIndexController} from "@/service/CoIndexController";
import CoIndexRecommendProductsDto from "@/service/model/index/CoIndexRecommendProductsDto";
import ProductsItem from '@/views/index/productItem/ProductItem.vue';
import {setSeo} from "@/utils/displayUtil";
import {getPhotoUrl} from "@/Directive";
import {useContext} from "@/Ph";
import AdsDetail from "@/service/model/index/AdsDetail";
import Promotion from "@/components/header/pcHeader/promoBanner/Promotion.vue";
import {useRoute} from "vue-router";
import HeaderDto from "@/service/model/header/HeaderDto";
import {itegtm} from "@/utils/gtm";
export default defineComponent({
    components: {Promotion,ProductsItem, Swiper, SwiperSlide},
    setup() {
        const context = useContext()
        const data = reactive({
            winStyle: <any> {},
            isShowNavCate: <boolean> false,
            coIndexDto: <CoIndexDto>{},
            bannerAds: <AdsDetail[]>[],
            loadIndexRecommendProducts: <CoIndexRecommendProductsDto>{},
            isShowBigSale: <boolean> false
        })
        let route=useRoute();
        let headInfo = <HeaderDto>inject('headInfo');
        onMounted(async () =>{
            // 首页轮播图
            data.bannerAds = data.coIndexDto.bannerAds;
            // 首页推荐商品
            data.loadIndexRecommendProducts = await coIndexController.loadIndexRecommendProducts()
            data.winStyle = {'height':  window.outerHeight + 'px'}
            data.isShowBigSale = ! headInfo.promotionSwitchOpen;
            if(!headInfo.simulatedLogin){
                itegtm.browse("home",headInfo.customerId);
            }
        })

        onServerPrefetch(async ()=>{
            let edmCouponCodes:string  = route.query.edmCouponCodes as string | undefined;
            data.coIndexDto = await coIndexController.index(edmCouponCodes);
            context.clientData['coIndexDto'] = data.coIndexDto;
            setSeo(data.coIndexDto.seo.title,data.coIndexDto.seo.keywords,data.coIndexDto.seo.description);
        });
        if(context.isClient) {
            data.coIndexDto = context.clientData['coIndexDto'];
            setSeo(data.coIndexDto.seo.title,data.coIndexDto.seo.keywords,data.coIndexDto.seo.description);
        }
        const onSwiper = (swiper) => {};
        const onSlideChange = () => {};

        const handleOpenNavCate = (flag) =>{
            data.isShowNavCate = flag
        }

        return {
            ...toRefs(data),
            modules: [Pagination, Autoplay, Navigation],
            onSwiper,
            onSlideChange,
            setSeo,
            getPhotoUrl,
            context,
            handleOpenNavCate
        }

    }

})